export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#00bcd4",
    },
    secondary: {
      main: "#FFB7C5",
    },
  },
};
